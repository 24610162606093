<template>
  <div
    v-if="show"
    class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center"
  >
    <div
      @click.self="close_modal()"
      class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
    ></div>

    <div
      class="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto"
    >
      <div class="modal-content py-4 text-left px-6">
        <div class="flex justify-center items-center pb-3">
          <p class="text-xl font-bold text-center">
            Deine Bewerbung als {{ job }}
          </p>
        </div>

        <form class="mt-6" @submit.prevent novalidate="true">
          <div class="mt-4">
            <div class="flex items-center justify-between">
              <label
                for="name"
                class="block text-sm text-gray-800 dark:text-gray-200"
                >Name&#42;</label
              >
            </div>

            <input
              type="text"
              v-model="name"
              class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-gray-600   focus:outline-none focus:ring"
            />
          </div>

          <div class="mt-4">
            <div class="flex items-center justify-between">
              <label
                for="email"
                class="block text-sm text-gray-800 dark:text-gray-200"
                >Telefon&#42;</label
              >
            </div>

            <input
              type="tel"
              v-model="phone"
              class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-dunkelblau dark:focus:border-dunkelblau focus:outline-none focus:ring"
            />
          </div>

          <div class="mt-4">
            <div class="flex items-center justify-between">
              <label
                for="email"
                class="block text-sm text-gray-800 dark:text-gray-200"
                >E-Mail&#42;</label
              >
            </div>

            <input
              type="email"
              v-model="mail"
              class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-dunkelblau dark:focus:border-dunkelblau focus:outline-none focus:ring"
            />
          </div>

          <div class="mt-4">
            <div class="flex items-center justify-between">
              <label
                for="cv"
                class="block text-sm text-gray-800 dark:text-gray-200"
                >Lebenslauf&#42;</label
              >
            </div>

            <div
              @change="onFileChange"
              class="flex w-full  mt-2 items-center justify-center bg-grey-lighter"
            >
              <label
                class="w-full flex justify-evenly items-center px-4 py-2 bg-white text-dunkelblau rounded-lg shadow-lg tracking-wide   border border-dunkelblau cursor-pointer hover:bg-gray-200 hover:text-dunkelblau"
              >
                <svg
                  class="w-6 h-6"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"
                  />
                </svg>
                <div class="flex flex-col">
                  <span class="text-sm">Lebenslauf auswählen</span>
                  <span v-if="fileName" class="text-sm"
                    >Deine Datei: {{ fileName }}</span
                  >
                </div>
                <input
                  type="file"
                  accept="application/pdf,application/vnd.ms-excel"
                  class="hidden"
                />
              </label>
            </div>
          </div>

          <div class="mt-4">
            <div class="flex items-center justify-between">
              <label
                for="email"
                class="block text-sm text-gray-800 dark:text-gray-200"
                >Wann können wir dich am besten erreichen?</label
              >
            </div>

            <input
              type="text"
              v-model="available"
              class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-dunkelblau dark:focus:border-dunkelblau focus:outline-none focus:ring"
            />
          </div>

          <div
            v-if="errors.length > 0"
            class="bg-red-400 p-3 rounded mt-2 w-full"
          >
            <p v-for="error in errors" :key="error" class="text-white  ">
              {{ error }}
            </p>
          </div>

          <div v-if="!showSuccessMessage" class="mt-2">
            <button
              type="submit"
              @click="checkForm"
              class="mt-10 bg-dunkelblau p-3 text-white w-full   rounded-lg  hover:bg-gray-800  font-bold text-center cursor-pointer"
            >
              <div class="flex justify-center items-center space-x-4">
                <span class="m-2  text-white">Jetzt bewerben! </span>

                <svg
                  v-if="showSpinner"
                  class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            </button>
          </div>
        </form>

        <div v-if="showSuccessMessage" class="bg-green-200 mt-6 rounded p-2">
          <p class="">Danke! Deine Bewerbung ist bei uns eingegangen.</p>
        </div>

        <div v-if="showSuccessMessage" class="mt-2">
          <button
            @click="close_modal()"
            class="mt-10 bg-dunkelblau p-3 text-white w-full   rounded-lg  hover:bg-gray-800  font-bold text-center cursor-pointer"
          >
            <span class="m-2  text-white">Schliessen</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");

export default {
  name: "Modal",

  props: {
    show: Boolean,
    job: String,
  },
  data() {
    return {
      name: "",
      mail: "",
      phone: "",
      file: "",
      showSuccessMessage: false,
      fileName: "",
      errors: [],
      base64PDF: "",
      available: "",
      showSpinner: false,
    };
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?render=" +
        process.env.VUE_APP_RECAPTCHA_SITE_KEY
    );
    document.head.appendChild(recaptchaScript);
  },
  methods: {
    checkForm() {
      this.errors = [];

      if (!this.name) {
        this.errors.push("Bitte trage deinen Namen ein!");
      }
      if (!this.mail) {
        this.errors.push("Bitte trage deine E-Mail Adresse ein!");
      } else if (!this.validEmail(this.mail)) {
        this.errors.push("Bitte trage eine gültige E-Mail Adresse ein!");
      }

      if (!this.phone) {
        this.errors.push("Bitte trage deine Telefonnummer ein!");
      }

      if (!this.file) {
        this.errors.push("Bitte lade deinen Lebenslauf hoch!");
      }

      if (this.file.size > 1024 * 1024 * 5) {
        this.errors.push("PDF Datei ist zu groß (> 5MB)");
      }

      if (!this.errors.length) {
        this.sendMessage();
      }
    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    onFileChange(event) {
      this.file = event.target.files[0];

      this.fileName = this.file.name;
      this.convertToBase64();
    },
    convertToBase64() {
      var fileToLoad = this.file;

      // FileReader function for read the file.
      var fileReader = new FileReader();

      // Onload of file read the file content
      fileReader.onload = (fileLoadedEvent) => {
        this.base64PDF = fileLoadedEvent.target.result.split(",")[1];
      };
      // Convert data to base64
      fileReader.readAsDataURL(fileToLoad);
    },
    sendMessage() {
      this.showSpinner = true;
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {
            action: "submit",
          })
          .then((token) => {
            const data = {
              token: token,
              formData: {
                receiverName: this.name,
                receiverMail: this.mail,
                receiverPhone: this.phone,
                receiverPDF: this.base64PDF,
                available: this.available,
                job: this.job,
                subject: "Ihre Bewerbung - ZW-Systems GmbH",
                htmlBody:
                  "Vielen Dank, dass du dich für diese Stelle interessierst. <br> Nach Prüfung deiner Unterlagen, melden wir uns kurzfristig bei dir. <br><br> Vielen Dank. <br><br> ZW Systems GmbH",
              },
            };
            axios
              .post(process.env.VUE_APP_BACKEND_URL + "career", data)
              .then((res) => {
                if (res.status === 200) {
                  this.showSuccessMessage = true;
                  this.showSpinner = false;
                }
              });
          });
      });
    },
    close_modal() {
      this.errors = [];
      this.$emit("close");
    },
  },
};
</script>
