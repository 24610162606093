<template>
  <section class="py-24 px-4 lg:px-20 bg-dunkelgruen border-b-2 border-white">
    <div
      class="flex flex-col  lg:flex-row w-full justify-between  items-center "
    >
      <div class="flex flex-col w-full lg:w-2/5 justify-start   mb-10 lg:mb-0">
        <p
          class="text-4xl lg:ml-16 lg:text-8xl font-thin text-white  uppercase"
        >
          App
        </p>
        <p class="text-4xl  lg:text-8xl font-thin text-white  uppercase">
          Entwickler
        </p>
      </div>

      <div class="flex flex-col   lg:w-2/5   lg:mt-0 i  ">
        <p class="text-xl  leading-loose text-white">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
          ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
          sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam
          et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
          takimata sanctus est Lorem ipsum dolor sit amet.
        </p>
        <div
          class="mt-10 bg-hellblau p-3 text-dunkelblau w-full lg:w-52 rounded-lg   font-bold text-center cursor-pointer"
        >
          Jetzt bewerben!
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Job3",
  setup() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
