<template>
  <div>
    <Hero />
    <Job1 />
    <Job2 />
<!--     <Job3 />
 -->  </div>
</template>

<script>
import Hero from "@/components/Career/Hero";
import Job1 from "@/components/Career/Job1";
import Job2 from "@/components/Career/Job2";
import Job3 from "@/components/Career/Job3";

export default {
  name: "Career",
  components: { Hero, Job1, Job2, Job3 },
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
