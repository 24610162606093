<template>
  <section class="py-24 px-4 lg:px-40 bg-dunkelblau border-b-2 border-white">
    <div
      class="flex flex-col  lg:flex-row w-full justify-between    "
    >
      <div class="flex flex-col w-full lg:w-2/5 justify-start mb-10 lg:mb-0 ">
        <p class="text-4xl  lg:text-6xl font-thin text-white  uppercase">
          App
        </p>
        <p class="text-4xl  lg:text-6xl font-thin text-white  uppercase">
          Entwickler
        </p>
      </div>

      <div class="flex flex-col   lg:w-3/5   lg:mt-0 i  ">
        <p class="text-lg  leading-loose text-white">
          Als App Entwickler bist du der Erfolgstreiber für einen hohen
          Kundennutzen in neuen digitalen Tools und Produkten. Du entwickelst
          intuitive Funktionen für neue Produkte und innovative Lösungen, die
          das Leben unserer Kunden und den Menschen verbessern. Du schaffst in
          enger Zusammenarbeit mit deinem Team ein herausragendes Erlebnis für
          die Nutzer. Dafür bieten wir dir den Freiraum, den du benötigst, um
          dich einzubringen, zu gestalten und um deinen Footprint durch echte
          digitale Lösungen in der Welt zu hinterlassen.
        </p>
        <p class="text-lg mt-8 leading-loose font-semibold text-white">
          Ihre Aufgaben:
        </p>
        <ul class="text-lg ml-10 leading-loose text-white list-disc">
          <li>
            Entwicklung von Apps für iOS und Android Systeme für
            Unternehmensanwendungen
          </li>
          <li>
            Konzeption, Modellierung und Umsetzung der Applikationen für
            Smartphones und Tablets
          </li>
        </ul>
        <p class="text-lg mt-8 leading-loose font-semibold text-white">
          Deine Skills:
        </p>
        <ul class="text-lg ml-10 leading-loose text-white list-disc">
          <li>Berufserfahrung im Bereich Softwareentwicklung</li>
          <li>
            praktische Erfahrungen in der Android- oder iOS-Entwicklung,
            Software-Design und REST- Services
          </li>
          <li>Idealerweise Erfahrungen in der Backend-Entwicklung</li>
        </ul>
        <div
          @click="showModal = true"
          class="mt-10 bg-hellblau p-3 text-dunkelblau w-full lg:w-52 rounded-lg  hover:bg-gray-400  font-bold text-center cursor-pointer"
        >
          Jetzt bewerben!
        </div>
      </div>
    </div>
    <Modal
      :show="showModal"
      job="App-Entwickler"
      @close="showModal = false"
    ></Modal>
  </section>
</template>

<script>
import Modal from "@/components/Career/Modal";

export default {
  name: "Job1",
  setup() {
    return {};
  },
  data() {
    return {
      showModal: false,
    };
  },
  components: { Modal },
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
