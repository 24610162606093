<template>
  <section class="py-24 px-4 lg:px-40 bg-hellblau border-b-2 border-white">
    <div class="flex flex-col  lg:flex-row w-full justify-between    ">
      <div
        class="flex lg:hidden mb-10 flex-col w-full lg:w-2/5 justify-start  "
      >
        <p
          class="text-4xl lg:ml-16 lg:text-6xl font-thin text-dunkelblau  uppercase"
        >
          Web
        </p>
        <p class="text-4xl   lg:text-6xl font-thin text-dunkelblau  uppercase">
          Entwickler
        </p>
      </div>
      <div class="flex flex-col   lg:w-3/5   lg:mt-0 i  ">
        <p class="text-lg  leading-loose text-dunkelblau">
          Du suchst einen neuen Job, eine neue Herausforderung? In einem
          sympathischen Unternehmen, klein genug, dass jede/r Einzelne etwas
          bewegen kann? Mit einer Unternehmenskultur, die von Teamwork,
          Leidenschaft und Vertrauen geprägt ist? Im Team begleitest Du als
          Webentwickler (w/m/d) die Entwicklung und Optimierung moderner und
          komplexer Webprojekte unter Einsatz neuester Technologien. Daraus
          ergeben sich anspruchsvolle technische Aufgaben zur Entwicklung von
          Lösungen für kundenspezifische Anforderungen.
        </p>
        <p class="text-lg mt-8 leading-loose font-semibold text-dunkelblau">
          Ihre Aufgaben:
        </p>
        <ul class="text-lg ml-10 leading-loose text-dunkelblau list-disc">
          <li>
            Konzeption und Neuentwicklung von neuen Internet-Applikationen
            innerhalb eines agilen Teams
          </li>
          <li>
            Planung und Umsetzung von API Schnitt-stellen in einer Microservice
            Architektur
          </li>
        </ul>
        <p class="text-lg mt-8 leading-loose font-semibold text-dunkelblau">
          Deine Skills:
        </p>
        <ul class="text-lg ml-10 leading-loose text-dunkelblau list-disc">
          <li>HTML 5</li>
          <li>
            PHP 7.3
          </li>
          <li>JavaScript (Frontend und Backend)</li>
          <li>MySQL</li>
        </ul>
        <div
          @click="showModal = true"
          class="mt-10 bg-dunkelblau p-3 text-white w-full lg:w-52 rounded-lg  hover:bg-gray-800  font-bold text-center cursor-pointer"
        >
          Jetzt bewerben!
        </div>
      </div>

      <div class="hidden lg:flex flex-col w-full lg:w-2/5 justify-start  ">
        <p
          class="text-6xl  text-right  lg:text-6xl font-thin text-dunkelblau  uppercase"
        >
          Web
        </p>
        <p
          class="text-6xl  text-right lg:text-6xl font-thin text-dunkelblau  uppercase"
        >
          Entwickler
        </p>
      </div>
    </div>
    <Modal
      :show="showModal"
      job="Web-Entwickler"
      @close="showModal = false"
    ></Modal>
  </section>
</template>

<script>
import Modal from "@/components/Career/Modal";

export default {
  name: "Job2",
  setup() {
    return {};
  },
  data() {
    return {
      showModal: false,
    };
  },
  components: { Modal },
  mounted() {},
  methods: {},
};
</script>
<style scoped></style>
